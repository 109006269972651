import fomanticInit from '@/js/fomantic'
import './styles.js'

import { uiInit, initializeWindow } from '@/js/ui'
import $ from 'jquery'

import 'select2'
import './tooltip.js'
import 'lightbox2'
import 'lightbox2/dist/css/lightbox.css'
import 'Hinclude/hinclude.js'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de.js'
import 'bootstrap-datepicker/build/build_standalone3.less'

initializeWindow(globalThis)
$(uiInit)
$(fomanticInit)
