import $ from 'jquery'
import 'fomantic-ui-less/semantic.less'
import 'fomantic-ui-less/definitions/globals/site.js'
import 'fomantic-ui-less/definitions/behaviors/api.js'
import 'fomantic-ui-less/definitions/behaviors/visibility.js'
import 'fomantic-ui-less/definitions/behaviors/form.js'
import 'fomantic-ui-less/definitions/behaviors/state.js'
import 'fomantic-ui-less/definitions/modules/transition.js'
import 'fomantic-ui-less/definitions/modules/embed.js'
import 'fomantic-ui-less/definitions/modules/modal.js'
import 'fomantic-ui-less/definitions/modules/accordion.js'
import 'fomantic-ui-less/definitions/modules/checkbox.js'
import 'fomantic-ui-less/definitions/modules/tab.js'
import 'fomantic-ui-less/definitions/modules/nag.js'
import 'fomantic-ui-less/definitions/modules/flyout.js'
import 'fomantic-ui-less/definitions/modules/dropdown.js'
import 'fomantic-ui-less/definitions/modules/sticky.js'
import 'fomantic-ui-less/definitions/modules/toast.js'
import 'fomantic-ui-less/definitions/modules/calendar.js'
import 'fomantic-ui-less/definitions/modules/sidebar.js'
import 'fomantic-ui-less/definitions/modules/rating.js'
import 'fomantic-ui-less/definitions/modules/progress.js'
import 'fomantic-ui-less/definitions/modules/popup.js'
import 'fomantic-ui-less/definitions/modules/dimmer.js'
import 'fomantic-ui-less/definitions/modules/slider.js'
import 'fomantic-ui-less/definitions/modules/shape.js'
import 'fomantic-ui-less/definitions/modules/search.js'

export default () => {
  $('.ui.accordion').accordion()
  $('.ui.nag').nag()
  $('.ui.slider').slider()
}
