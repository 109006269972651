import $ from 'jquery'
import * as R from 'ramda'
import { selectDataNetwork } from '@/js/utils/unit-form.js'

$(function () {
  const networkSet = $('input[data-network]')

  networkSet.change(function (event) {
    const newValues = networkSet.get().reduce(function (acc, value) {
      return value.checked ? R.append(value.dataset.network, acc) : acc
    }, [])
    const oldValues = event.target.checked
      ? R.without([event.target.dataset.network], newValues)
      : R.append(event.target.dataset.network, newValues)
    const filteredValues = selectDataNetwork(newValues, oldValues)

    networkSet.each(function (_, checkbox) {
      checkbox.checked = filteredValues.includes(checkbox.dataset.network)
    })
  })
})
